/*
* @Description:推荐课程 组件
* @Version: 1.0
* @Author: Wangfan
*/
<template>
  <div class="hot-background">
    <div class="popularCases">
      <div class="header">
        <div class="case-title">
          <img src="~@/assets/index/hotCases.png" alt="" width="60" height="32">
          <span>审计案例</span>
        </div>
        <div>
          <span class="all-case" @click="toCaseCenter">更多<i class="el-icon-arrow-right"></i></span>
        </div>
      </div>
      <case-cards :case-cards-list="popularCasesList" @toCaseDetails="toCaseDetails"></case-cards>
    </div>
  </div>
</template>

<script>
  import CaseCards from '@/components/project/casecards/PCaseCardsBase'

  export default {
    name: 'PopularCases',
    components: { CaseCards },
    props: {
      popularCasesList: {
        type: Array,
        default: () => [],
        required: true
      }
    },
    methods: {
      toCaseDetails(params) {
        let id = params.id || -1
        this.$router.push({
          path: '/audit/caseDetails',
          query: {
            id: id
          }
        })
      },
      toCaseCenter() {
        this.$router.push({
          path: '/bayareacertification/caseCenter',
          query: {
            sortType: 1
          }
        })
      }
    }
  }
</script>

<style scoped lang="less">
.hot-background {
  width: 100%;
  background-color: #FFFFFF;
}

.popularCases {
  width: @banner-width;
  margin: 0 auto;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-top: 20px;
    font-size: 28px;
    font-weight: bold;
    text-align: left;

    .case-title {
      display: flex;
      align-items: center;
      font-size: 20px;
    }

    .all-case {
      font-size: 13px;
      color: #9199a1;
      margin-right: 20px;
      cursor: pointer;
    }
  }
}

</style>
