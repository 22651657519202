import { getAction } from '@/api/manage'
import { getLocalStorage, setLocalStorage } from '@/utils/localStorage'
import { COURSE_CATEGORY } from '@/store/mutation-types'
import { request } from '@/utils/axios'

/**
 * 获取所有课程分类，返回的res.treeData为树形结构
 * @returns {Promise<res>}
 */
export async function getCourseCategoryAll() {
  let res = {}

  // 优先从缓存中读取字典配置
  res.result = getLocalStorage(COURSE_CATEGORY)
  if (res.result) {
    res.success = true
    return Promise.resolve(res)
  }

  // 后台去除鉴权后换成getAction
  // res = await getAction(`/api/v1/course/courseCategory/all`)
  res = await request({
    url: `/api/v1/course/courseCategory/all`,
    method: 'get',
    verifyToken: true
  })


  if (!res.success) {
    return Promise.resolve(res)
  }
  let categoryList = []
  let subClassList = []
  res.list.forEach(item => {
    if (item.level === 1) {
      categoryList.push(item)
    } else if (item.level === 2) {
      subClassList.push(item)
    }
  })
  res.result = { categoryList, subClassList }

  // 存到缓存里
  setLocalStorage(COURSE_CATEGORY, res.result, 24 * 3600)
  return Promise.resolve(res)
}
