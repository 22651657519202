<template>
  <div class="empty">
    <div>
      <div>
        <img src="@/assets/empty.png" alt="" width="300" height="300">
      </div>
      <div class="content">
        <p class="desc" v-html="emptyText.replace(/\n/gm, '<br>')"></p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    // 统一空白样式
    name: 'PEmpty',
    props: {
      emptyText: {
        type: String,
        default: '暂无数据'
      }
    }
  }
</script>

<style lang="less" scoped>
  .empty {
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      text-align: center;

      .desc {
        color: rgba(0, 0, 0, .45);
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 50px;
      }
    }
  }
</style>
