<template>
  <div class="jump-box">
    <ul class="box-list">
      <li class="box-item" v-for="item in boxList" :key="item.id">
        <span class="left-title">
          {{ item.title }}
        </span>
        <i class="right-icon el-icon-arrow-right"></i>
        <ul class="class-list">
          <li class="class-item" v-for="classItem in item.classList" :key="classItem.id" :title=" classItem.title">
            {{ classItem.title }}
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'JumpBox',
    props: {
      boxList: {
        type: Array,
        default: () => []
      }
    },
    created() {
      console.log(this.boxList)
    }

  }
</script>

<style lang="less" scoped>
.jump-box {
  width: 200px;
  height: 100%;
  background: rgba(0, 0, 0, .6784313725);
  color: #cccccc;

  .box-list {
    border-radius: 10px;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;

    .box-item {
      width: 100%;
      margin-left: 10px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all .3s;

      .left-title {

      }

      .right-icon {
        transition: all .3s;
        font-weight: bold;
      }

      .class-list {
        height: 100%;
        width: 300px;
        padding: 0 30px;
        overflow-y: auto;
        overflow-x: hidden;
        display: none;
        z-index: 9999;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(100%);
        background-color: #fff;
        color: #333333;
        transition: all .3s;

        .class-item {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 700;
          padding-top: 20px;
          transition: all .3s;
        }

        .class-item:hover {
          cursor: pointer;
          color: #d51423;
        }
      }
    }

    .box-item:hover {
      border-radius: 10px 0 0 10px;
      cursor: pointer;
      color: #d51423;
      background-color: #E7ECED;

      .right-icon {
        margin-right: 10px;
      }

      .class-list {
        display: block;
      }
    }
  }
}
</style>
