<template>
  <div>
    <div class="homeIndex">
      <!--      大背景+文字-->
      <div class="banner-show">
        <div class="banner-img">
          <!--logo-->
          <div>
            <img src="~@/assets/index/logo.png" alt="" width="135" height="135">
          </div>
          <!--文字-->
          <div class="banner-text">
            <div class="text1">广东粤港澳大湾区认证促进中心</div>
            <div class="text2">GREATER BAY AREA CERTIFICATION ALLIANCE</div>
            <div class="text3"> ——湾区品质 服务全球——</div>
          </div>
          <!--按钮-->
          <div class="jumpBox-area">
            <div @click="toApply">
              <img src="@assets/index/apply.png" alt="">
              认证申请
            </div>
            <div @click="toQuery">
              <img src="@assets/index/query.png" alt="">
              认证查询
            </div>
            <!--            <div @click="toStandard">-->
            <!--              <img src="@assets/index/stranded.png" alt="">-->
            <!--              认证标准-->
            <!--            </div>-->
          </div>
        </div>
      </div>
      <!--      中心资讯-->
      <el-skeleton :rows="10" animated v-show="loading" class="center_show"/>
      <div v-show="!loading" class="center-dynamic">
        <div class="text-line">
          <img src="~@/assets/index/news.png" alt="" width="42" height="42">
          <span>中心资讯</span>
          <div class="bottom-line"></div>
        </div>
        <div class="news-box">
          <div class="empty" v-if="newsIndexList.length === 0">
            <el-empty description="暂无内容"></el-empty>
          </div>
          <template v-else>
            <!--          轮播图-->
            <SlideShow style="width: 49%;" :slideShowImgs="newsIndexList"/>
            <div class="new-news">
              <div class="top-box">
                <div>
                  最新资讯
                </div>
                <router-link to="/bayareacertification/caseCenter">
                  <span>更多</span>
                  <img src="~@/assets/index/more.png" alt="" width="12" height="12">
                </router-link>
              </div>
              <div class="new-news-list">
                <!--              动态列表-->
                <div
                  class="news-item"
                  v-for="(item,index) in newsIndexList"
                  :key="index"
                  @click="routerToNewsDetails(item)">
                  <div class="date-box">
                    <div class="box-top">{{ item.date.split('-')[2] }}</div>
                    <div class="box-bottom">{{ item.date.split('-')[0] }}-{{ item.date.split('-')[1] }}</div>
                  </div>
                  <div class="text-box">
                    <div class="news-title">{{ item.title }}</div>
                    <div class="news-content" v-html="item.content"></div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <!--      <div class="audit-box">-->
      <!--        &lt;!&ndash;      认证名单&ndash;&gt;-->
      <!--        <div class="audit-list">-->
      <!--          <div class="title-line">-->
      <!--            <img src="~@/assets/index/audit-list.png" alt="" width="42" height="42">-->
      <!--            <span>认证名单</span>-->
      <!--            <img class="line-img" src="~@/assets/index/title-line.png" alt="">-->
      <!--            <div class="more" @click="routerToAuditList">More ></div>-->
      <!--          </div>-->
      <!--          <div class="audit-list-box">-->
      <!--            <div-->
      <!--              class="audit-list-item"-->
      <!--              v-for="(item,index) in newsList"-->
      <!--              :key="index"-->
      <!--              @click="routerToAuditListDetails(item)">-->
      <!--              <div class="item-text">{{ item.title }}</div>-->
      <!--              <div class="item-date">{{ item.date }}</div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        &lt;!&ndash;      认证故事&ndash;&gt;-->
      <!--        <div class="audit-story">-->
      <!--          <div class="title-line">-->
      <!--            <img src="~@/assets/index/audit-story.png" alt="" width="42" height="42">-->
      <!--            <span>认证故事</span>-->
      <!--            <img class="line-img" src="~@/assets/index/title-line.png" alt="">-->
      <!--            <div class="more" @click="routerToAuditStory">More ></div>-->
      <!--          </div>-->
      <!--          <div class="audit-story-box">-->
      <!--            <div-->
      <!--              v-if="index<4"-->
      <!--              class="audit-list-item"-->
      <!--              v-for="(item,index) in newsList"-->
      <!--              :key="index"-->
      <!--              @click="routerToAuditStoryDetails(item)">-->
      <!--              <div v-if="index===0" class="item-text-img">-->
      <!--                <img class="item-img" :src="item.coverPictureUrl" alt="" height="100%">-->
      <!--                <div class="text-box">-->
      <!--                  <div class="item-text">{{ item.title }}</div>-->
      <!--                  <div class="item-content">{{ item.content }}</div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--              <div class="flex" v-else>-->
      <!--                <div class="item-text">{{ item.title }}</div>-->
      <!--                <div class="item-date">{{ item.date }}</div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      认证机构-->
      <div>
        <div class="text-line">
          <img src="~@/assets/index/audit-company.png" alt="" width="42" height="42">
          <span>指定认证机构</span>
          <div class="bottom-line"></div>
        </div>
        <div class="intr-wrap">
          <div class="empty" v-if="companyList.length === 0">
            <el-empty description="暂无内容"></el-empty>
          </div>
          <template v-else>
            <div class="audit-company" v-for="(item,index) in companyList" :key="index">
              <img
                v-if="index>4 && index !==5 && index !==7"
                class="img-style"
                :src="item.img"
                alt=""
                width="200"
                height="100%"
                @click="toSite(item.url)">
              <img
                v-else-if="index===3"
                style="padding: 20px"
                :src="item.img"
                alt=""
                width="200"
                height="100%"
                @click="toSite(item.url)">
              <img
                v-else-if="index===5"
                class="img5-style"
                :src="item.img"
                alt=""
                @click="toSite(item.url)">
              <img
                v-else-if="index===7"
                style="padding: 25px 60px"
                :src="item.img"
                alt=""
                width="200"
                height="100%"
                @click="toSite(item.url)">
              <img v-else :src="item.img" alt="" width="200" height="100%" @click="toSite(item.url)">
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import JumpBox from './modules/JumpBox'
  import PLayoutBase from '@/components/project/layout/PLayoutBase'
  import PopularCases from './modules/PopularCases'
  import SlideShow from './modules/SlideShow'
  import NewCases from './modules/newCases'
  import TabTitle from './modules/TabTitle'
  import ThematicCases from './modules/ThematicCases'
  import {
    slideShowImgs,
    newsList,
    companyList,
    auditCategoryList
  } from '@/api/mock/homeIndex'
  import { getBannerDataInfo } from '@/api/homeIndex'
  import { getSpecialCasesDataInfo } from '@/api/topic'
  import { queryCourse } from '@api/course'
  import { SortType } from '@/enum/sortType'
  import PopularPPT from './modules/PopularPPT'
  import { getAction } from '@api/manage'
  import { message } from 'ant-design-vue'

  export default {
    name: 'Index',
    components: {
      PopularPPT,
      JumpBox,
      TabTitle,
      NewCases,
      SlideShow,
      PopularCases,
      PLayoutBase,
      ThematicCases
    },
    data() {
      return {
        loading: true,
        slideShowImgs: slideShowImgs,
        newsIndexList: [],
        companyList: [],
        popularCasesList: [],
        newCasesList: [],
        auditCategoryList: auditCategoryList,
        leftBoxList: [],
        thematicList: [],
        queryPage: 1,
        querySize: 8
      }
    },
    created() {
      this.init()
    },
    methods: {
      init() {
        let promiseList = [this.loadNewsData(), this.getAuthenticationInstitutionData()]
        this.loading = true
        Promise.all(promiseList).finally(() => {
          this.loading = false
        })
      },
      loadNewsData() {
        let params = {
          dynamicType: '0',
          queryPage: 1,
          querySize: 5
        }
        return new Promise((resolve, reject) => {
          getAction('/api/v1/dms/centralDynamics/brief', params).then((res) => {
            if (res.success) {
              if (res && res.list && res.list.length > 0) {
                this.newsIndexList = res.list.map(item => {
                  return {
                    id: item.id,
                    coverPictureUrl: item.coverImage,
                    title: item.title,
                    date: item.releaseTime.slice(0, 10),
                    content: item.content,
                    type: item.dynamicType
                  }
                })
              } else {
                this.newsIndexList = []
              }
              resolve(res)
            } else {
              this.newsIndexList = []
              message.error(res.message)
              reject(res)
            }
          }).catch((err) => {
            this.newsIndexList = []
            message.error(err.message)
            reject(err)
          })
        })
      },
      // 获取指定认证机构数据
      getAuthenticationInstitutionData() {
        return new Promise((resolve, reject) => {
          getAction('/api/v1/dms/designatedCertificationBody/all').then((res) => {
            if (res.success) {
              if (res && res.list && res.list.length > 0) {
                this.companyList = res.list.map(item => {
                  return {
                    img: item.logo,
                    url: item.url
                  }
                })
              } else {
                this.companyList = []
              }
              resolve(res)
            } else {
              this.companyList = []
              message.error(res.message)
              reject(res)
            }
          }).catch((err) => {
            this.companyList = []
            message.error(err.message)
            reject(err)
          })
        })
      },
      toSite(url) {
        window.open(url, '_blank')
      },
      toApply() {
        this.$router.push('/bayareacertification/application')
      },
      toQuery() {
        this.$router.push('/bayareacertification/query')
      },
      toStandard() {
        this.$router.push('/bayareacertification/technicalnorm')
      },
      routerToAuditList() {
        this.$router.push('/bayareacertification/auditList')
      },
      routerToAuditStory() {
        this.$router.push('/bayareacertification/auditStory')
      },
      routerToNewsDetails(item) {
        this.$router.push({
          path: '/bayareacertification/caseCenterDetail',
          query: {
            newsId: item.id,
            type: item.type
          }
        })
      },
      routerToAuditStoryDetails(item) {
        this.$router.push({
          path: '/bayareacertification/auditStoryDetail',
          query: {
            id: item.id
          }
        })
      },
      routerToAuditListDetails(item) {
        this.$router.push({
          path: '/bayareacertification/auditListDetail',
          query: {
            id: item.id
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
.homeIndex {
  //width: @banner-width;
  //margin: 0 auto;
  overflow: hidden;
  height: 100%;
  background-color: #FFFFFF;
  margin-bottom: 50px;

  .tab-box {
    width: @banner-width;
    margin: 0 auto;
  }
}

.banner-show {
  position: relative;
  //border-radius: 10px 10px 0 0;
  border-radius: 10px;
  width: 100%;
  height: 100%;

  .banner-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: url("~@/assets/index/banner_bg.png") no-repeat;
    background-size: 100% 100%;
    height: 920px;
    padding-top: 120px;
    font-family: SourceHanSerif-semiBold;

    .banner-text {
      color: #FFFFFF;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      font-family: SourceHanSerif-semiBold;

      .text1 {
        font-size: 48px;
        font-family: SourceHanSerif-semiBold;
      }

      .text2 {
        font-size: 28px;
        margin-top: 20px;
        font-family: SourceHanSerif-semiBold;
      }

      .text3 {
        font-size: 24px;
        font-weight: bold;
        margin-top: 20px;
        font-family: SourceHanSerif-semiBold;
      }
    }
  }

  .jumpBox-area {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    position: absolute;
    bottom: 65px;
    left: 50%;
    transform: translateX(-50%);

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 79px;
      border-radius: 10px;
      background-color: rgba(76, 139, 167, 0.71);
      color: rgba(255, 255, 255, 1);
      font-size: 26px;
      text-align: center;
      border: 2px solid rgba(255, 255, 255, 1);
      margin: 0 20px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      img {
        margin-right: 5px;
      }
    }
  }

}

.center_show {
  .banner()
}

.center-dynamic {
  width: 1440px;
  margin: 40px auto 40px;
  background-color: #FFFFFF;

  .news-box {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 495px;

    .new-news {
      display: flex;
      flex-direction: column;
      width: 49%;

      .top-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;

        div {
          font-size: 20px;
          font-weight: bold;
        }

        a {
          display: flex;
          align-items: center;
          color: #1F2833;
          font-size: 16px;

          &:hover {
            color: @primary-color;
            opacity: 0.8;
          }
        }
      }

      .new-news-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;

        .news-item {
          display: flex;
          align-items: center;
          height: 69px;
          width: 100%;
          margin: 10px 0;
        }

        .date-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 69px;
          border-radius: 5px;
          border: 1px solid rgba(47, 137, 187, 1);

          .box-top {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80px;
            font-size: 24px;
            color: #ffffff;
            background: linear-gradient(180deg, rgba(25, 53, 167, 1) 0%, rgba(47, 137, 187, 1) 100%);
            height: 32px;
          }

          .box-bottom {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80px;
            font-size: 16px;
            border-radius: 5px;
            color: rgba(25, 53, 167, 1);
            background-color: rgba(255, 255, 255, 1);
            height: 37px;
          }
        }

        .text-box {
          width: calc(100% - 80px);
          margin-left: 15px;
          cursor: pointer;
          transition: 0.3s all;

          &:hover {
            color: @primary-color;
          }

          .news-title {
            width: calc(100% - 80px);
            font-size: 18px;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .news-content {
            font-size: 14px;
            text-indent: 2em; /* 首行缩进2个字符 */
            overflow: hidden;
            text-overflow: ellipsis;
            justify-content: center;
            display: -webkit-box; /* 设置为WebKit内核的弹性盒子模型 */
            -webkit-box-orient: vertical; /* 垂直排列 */
            -webkit-line-clamp: 2; /* 限制显示两行 */
          }
        }
      }
    }
  }
}

.empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-line {
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  margin: 0 auto 24px;
  border-bottom: 1px solid rgba(187, 187, 187, 1);

  span {
    margin-left: 5px;
    font-size: 24px;
    font-weight: bold;
  }

  .bottom-line {
    position: absolute;
    bottom: 0px;
    width: 30px;
    border-bottom: 3px solid rgba(25, 53, 167, 1);
  }
}

.audit-box {
  width: 1440px;
  margin: 60px auto 60px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .audit-list {
    width: 48%;

    .audit-list-box {
      width: 100%;
      height: 400px;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.78);
      color: rgba(16, 16, 16, 1);
      font-size: 14px;
      text-align: center;
      //border: 1px solid rgba(187, 187, 187, 0.44);
    }

    .audit-list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;
      font-size: 20px;
      cursor: pointer;
      border-bottom: 1px dashed rgba(187, 187, 187, 1);

      &:hover {
        color: rgba(25, 53, 167, 1);
      }

      &:nth-child(1) {
      }

      .item-text {
        text-align: left;
        width: calc(100% - 100px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .item-date {
        width: 150px;
      }
    }
  }

  .audit-story {
    width: 48%;

    .audit-story-box {
      width: 100%;
      height: 400px;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 1);
      color: rgba(16, 16, 16, 1);
      font-size: 14px;
      text-align: center;
      //border: 1px solid rgba(187, 187, 187, 0.52);

      .audit-list-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 20px;
        cursor: pointer;
        border-bottom: 1px dashed rgba(187, 187, 187, 1);

        &:hover {
          color: rgba(25, 53, 167, 1);
        }

        &:nth-child(1) {
        }

        .flex {
          width: 100%;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .item-text {
            text-align: left;
            width: calc(100% - 100px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .item-date {
            width: 150px;
          }

        }

        .item-text-img {
          width: 100%;
          height: 160px;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .text-box {
            width: calc(100% - 150px);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            margin-left: 20px;

            .item-text {
              width: 300px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-bottom: 10px;
            }

            .item-content {
              width: 100%;
              height: 80px;
              font-size: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              display: -webkit-box;
              -webkit-box-orient: vertical; /* 垂直排列 */
              -webkit-line-clamp: 3;
            }
          }

          .item-img {
            width: 125px;
            height: 125px;
          }
        }
      }
    }
  }

  .title-line {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .line-img {
      position: absolute;
      left: 0;
      bottom: -20px;
    }

    .more {
      position: absolute;
      font-size: 20px;
      right: 0;
      bottom: 0;
      cursor: pointer;
      transition: 0.3s all;

      &:hover {
        color: rgba(25, 53, 167, 1);
      }
    }

    span {
      margin-left: 5px;
      font-size: 24px;
      font-weight: bold;
    }
  }
}

.intr-wrap {
  width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.audit-company {
  width: 230px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  margin-bottom: 16px;
  background: #fff;
  box-shadow: 0 4px 16px #08174f14;
  border-radius: 4px;
  transition: all .4s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-4px);
  }
}

.img-style {
  padding: 25px 10px
}

.img5-style {
  width: 200px;
}
</style>
