/*
* @Description:首页大轮播 组件
* @Version: 1.0
* @Author: Wangfan
*/
<template>
  <el-row class="slideShow" v-loading="slideShowLoading">
    <el-carousel arrow="always" class="photo" :span="24" ref="cardShow" height="100%">
      <el-carousel-item
        v-for="(item,index) in slideShowImgs"
        :key="index"
        @click.native="routerTo(item)">
        <a href="javascript:0"><img :src="item.coverPictureUrl" alt=""></a>
        <div class="bottom-title">{{ item.title }}</div>
      </el-carousel-item>
    </el-carousel>
  </el-row>
</template>

<script>
  import { routerToTarget } from '@/utils/routerToTarget'

  export default {
    name: 'SlideShow',
    props: {
      slideShowImgs: {
        type: Array,
        default: () => [],
        required: true
      }
    },
    data() {
      return {
        slideShowLoading: false
      }
    },
    created() {
      this.slideShowLoading = false
    },
    watch: {
      slideShowImgs: {
        deep: true,
        handler() {
          this.slideShowLoading = false
        }
      }
    },
    methods: {
      routerTo(item) {
        this.$router.push({
          path: '/bayareacertification/caseCenterDetail',
          query: {
            newsId: item.id,
            type: item.type
          }
        })
      },
      arrowClick(val) {
        if (val === 'right') {
          this.$refs.cardShow.next()
        } else {
          this.$refs.cardShow.prev()
        }
      }
    }

  }
</script>

<style scoped lang="less">
.slideShow {
  position: relative;
  height: 100%;
  //margin-bottom: 13px;

  .photo {
    height: 100%;

    img {
      width: 100%;
      height: calc(100% - 50px);
    }
  }

  .photo:hover {
    cursor: pointer;
  }
}

///deep/ .el-carousel__indicators--horizontal {
//  left: 94%;
//  transform: translateX(-10%);
//}

.bottom-title {
  position: absolute;
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  background-color: rgba(8, 8, 8, 0.51);
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/deep/ .el-carousel__button {
  height: 6px;
}

/deep/ .el-carousel__indicators--horizontal {
  bottom: 45px;
}
</style>
