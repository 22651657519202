/*
* @Description:首页专题案例 组件
* @Version: 1.0
* @Author: Wangfan
*/
<template>
  <div class="specialCases">
    <p-empty v-if="specialCasesList.length === 0"></p-empty>
    <div class="courseList" v-loading="CourseLoading" v-else>
      <div
        class="course"
        v-for="item in specialCasesList"
        :key="item.id"
        v-copytouch="()=>routerTo(item)">
        <div>
          <img :src="item.coverPictureUrl" alt="">
        </div>
        <div class="description">
          <div class="title">
            <ep-ellipsis class="categoryName" :lines="2" :title="item.title">
              {{ item.title }}
            </ep-ellipsis>
          </div>
          <span class="company">
            <ep-ellipsis :lines="2" :title="item.locationUnitName">
              <span>单位: &nbsp;<i>{{ item.locationUnitName }}</i></span>
            </ep-ellipsis>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import highlightKeywords from 'easy-project-vue/dist/utils/highlightKeywords.js'
  import PEmpty from '@comp/project/empty/PEmpty'

  export default {
    name: 'SpecialCases',
    props: {
      specialCasesList: {
        type: Array,
        default: () => [],
        required: true
      }
    },
    components: {
      PEmpty
    },
    data() {
      return {
        // 热门案例加载动画
        CourseLoading: false
      }
    },
    created() {
      this.CourseLoading = false
    },
    watch: {
      // 进行监听，数据值改变（存在）时，动画结束
      newCasesList() {
        this.CourseLoading = false
      }
    },
    mounted() {

    },
    methods: {
      highlightKeywords,
      routerTo(params) {
        this.$emit('toCaseDetails', params)
      }
    }
  }
</script>

<style scoped lang="less">
.specialCases {
  border-radius: 16px;
  background-color: var(--white-1);

  .courseList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;

    .course {
      position: relative;
      display: flex;
      overflow: hidden;
      width: calc(50% - 12px);
      border-radius: 8px;
      margin-bottom: 10px;
      box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.3);
      background-color: var(--white-2);

      img {
        width: 252px;
        height: 140px;
        border-radius: 8px;
      }

      .description {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 14px 24px;

        .title {
          color: #3e454d;
          margin-bottom: 8px;
          font-size: 16px;
          line-height: 24px;
        }


        .company {
          color: #545c63;
          font-size: 14px;
          height: 38px;
        }

        .content {
          width: 647px;
          height: 90px;
          line-height: 25px;
          //margin-bottom: 10px;
        }
      }

      .btn_center {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .study_btn:hover {
        opacity: 0.75;
      }
    }

    .course:nth-last-child(-n+2) {
      margin-bottom: 0;
    }

    .course:hover {
      cursor: pointer;
      z-index: 2;
      -webkit-box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
      box-shadow: 0px 4px 6px 0px rgba(225, 227, 227, 100);
      -webkit-transform: translate3d(0, -2px, 0);
      transform: translate3d(0, -2px, 0);

      .description .title {
        color: @primary-color;
      }
    }
  }

}

</style>
