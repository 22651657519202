<template>
  <div class="newCases">
    <div class="header">
      <div class="case-title">
        <img src="~@/assets/index/newCases.png" alt="" width="60" height="32">
        <span>审计课件</span>
      </div>
      <div>
        <span class="all-case" @click="routerToPPTStudy">更多<i class="el-icon-arrow-right"></i></span>
      </div>
    </div>
    <case-cards :case-cards-list="pptList" @toCaseDetails="toCaseDetails"></case-cards>
  </div>
</template>

<script>
  import CaseCards from '@/components/project/casecards/PCaseCardsBase'

  export default {
    name: 'PopularPPT',
    components: { CaseCards },
    props: {},
    data() {
      return {
        IMAGE_URL: process.env.VUE_APP_PPT_IMAGE_URL,
        pptList: []
      }
    },
    created() {
      this.loadData(1, false)
    },
    methods: {
      loadData(arg, isLoading = true) {
        return new Promise(resolve => {
          if (isLoading) {
            this.loading = true
          }
          // 获取数据
          let data = require('@/views/pptstudy/assets/static/data/ppt-swiper.json')
          console.log(data)
          this.pptList = JSON.parse(JSON.stringify(data))
          let list = this.pptList.map((item) => {
            // item.coverUrl = require('@/views/pptstudy/assets/static/img/' + item.coverPicture)
            item.coverUrl = this.IMAGE_URL + item.coverPicture
            return item
          })
          // 只展示前八个ppt课件
          this.pptList = list.splice(0, 8)
          this.$nextTick(() => {
            this.loading = false
          })
        })
      },
      toCaseDetails(params) {
        let id = params.id || -1
        this.$router.push({
          path: '/audit/pptStudyDetails',
          query: {
            id: id
          }
        })
      },
      routerToPPTStudy() {
        this.$router.push({
          path: '/audit/pptStudy',
          query: {
            CategoryId: '全部'
          }
        })
      }
    }
  }
</script>

<style scoped lang="less">
.newCases {
  width: @banner-width;
  margin: 0 auto;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: bold;
    text-align: left;

    .case-title {
      display: flex;
      align-items: center;
      font-size: 20px;
    }

    .all-case {
      font-size: 13px;
      color: #9199a1;
      margin-right: 20px;
      cursor: pointer;
    }
  }
}

</style>
