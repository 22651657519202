/*
* 排序依据
*/

export const SortType = {
  // 综合排序
  COMPREHENSIVE_RANKING: undefined,
  // 最热课程 按点击量排序
  HOT: '1',
  // 最新课程 按时间排序
  NEW: '2',
  // 最多收藏
  MOST_COLLECTED: '3'
}

export const SortTypeOptions = [
  {
    label: '综合排序',
    value: SortType.COMPREHENSIVE_RANKING
  },
  {
    label: '最热',
    value: SortType.HOT
  },
  {
    label: '最新',
    value: SortType.NEW
  },
  {
    label: '最多收藏',
    value: SortType.MOST_COLLECTED
  }
]
