/*
* @Description:辅导培训 组件
* @Version: 1.0
* @Author: Wangfan
*/
<template>
  <div class="bgc">
    <div class="w">
      <div class="tab">
        <div
          v-for="(item,index) in auditCategoryTabList"
          :key="item.index"
          class="tabItem"
          v-copytouch="()=>routerTo({name:'caseDetails',params:{activeName:'first',id:item.id}})">
          <img :src="item.logoPictureUrl" alt="tab图标" style="width: 48px;height: 48px">
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getCourseCategoryAll } from '@api/courseCategory'

  export default {
    name: 'TabTitle',
    props: {
      auditCategoryList: {
        type: Array,
        default: () => [],
        required: true
      }
    },
    data() {
      return {
        tabLoading: false,
        auditCategoryTabList: []
      }
    },
    created() {
      this.loadTabTitle()
    },
    watch: {
      coachingTrainingTitle() {
        this.tabLoading = false
      }
    },
    methods: {
      loadTabTitle() {
        this.tabLoading = true
        getCourseCategoryAll().then(res => {
          if (!res.success) {
            this.$message.error(res.message)
            return
          }
          this.auditCategoryTabList = res.result.categoryList.length > 7 ? res.result.categoryList.slice(0, 7) : res.result.categoryList
        }).finally(() => {
          this.loading = false
        })
      },
      routerTo(params) {
        let router = this.$router.resolve({
          path: '/bayareacertification/caseCenter',
          query: {
            CategoryId: params.params.id
          }
        })
        // 点击跳转非当前页面
        window.open(router.href, '_blank')
      }
    }
  }
</script>

<style scoped lang="less">
.bgc {
  height: 110px;
  box-shadow: 0px 5px 20px 0px rgba(12, 23, 51, 30%);
  border-radius: 0px 0px 7px 7px;
  margin-bottom: 20px;

  .tab {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tabTitle {
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        display: inline-block;
        height: 48px;
        width: 5px;
        background-color: rgba(255, 92, 72, 100);
      }

      span {
        width: 20px;
        line-height: 20px;
        font-weight: bold;
        color: rgba(16, 16, 16, 100);
        font-size: 16px;
      }

    }

    .tabContext {
      float: left;
      display: flex;
      justify-content: space-around;
      height: 96px;
      align-items: center;

      .shadow {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 7px;
        width: 44px;
        height: 45px;
        border-radius: 14px;
        background-color: rgba(255, 92, 72, 100);
      }
    }

    .tabItem {
      display: flex;
      flex: 1;
      align-items: center;
      padding: 10px 13px !important;
      height: 110px;
      font-size: 15px;
      background-color: #fff;
      border-radius: 5px;

      span {
        margin-left: 5px;
      }
    }

    .tabItem:hover {
      cursor: pointer;
      //height: 100px;
      //box-shadow: 4px 4px 6px 0px rgba(225, 227, 227, 100);
    }
  }

}
</style>
