/*
* @Description:卡片组件
* @Version: 1.0
* @Author: Zhang Ziyuan
*/
<template>
  <div class="courseList" v-loading="caseLoadingAnimation">
    <p-empty v-if="caseCardsList.length === 0"></p-empty>
    <el-row :gutter="24" v-else>
      <el-col
        :span="span"
        v-for="item in caseCardsList"
        :key="item.id">
        <div class="course" v-copytouch="()=>routerTo(item)">
          <div>
            <p-image
              :src="item.coverUrl"
              alt=""
              :style="{ width:'100%',height:'157px'}">
            </p-image>
          </div>

          <div class="description">
            <div class="title">
              <span slot="reference" v-html="highlightKeywords(item.name,searchValue)"> </span>
              <span v-if="item.invalidityCause === effectiveType.OFF_SHELF">【已下架】</span>
              <span v-if="item.invalidityCause === effectiveType.DELETED">【已删除】</span>
            </div>
            <span class="number">
                <span>
                  <p-icon icon="icon-shijian" style="width: 18px;height: 18px;margin-right: 4px"></p-icon>
                  {{ item.releaseTime ? item.releaseTime.slice(0, 10) : '-' }}
                </span>
                <span>
                  <p-icon icon="icon-xuexirenshu-copy" style="width: 18px;height: 18px;margin-right: 4px"></p-icon>
                  {{ item.viewCount ? item.viewCount : '0' }}人学习
                </span>
                <span>
                  <p-icon icon="icon-shoucang" style="width: 18px;height: 18px;margin-right: 4px"></p-icon>
                  {{ item.collectCount ? item.collectCount : '0' }}人收藏
                </span>
            </span>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import highlightKeywords from 'easy-project-vue/dist/utils/highlightKeywords.js'
  import PImage from '../image/PImage'
  import PEmpty from '@comp/project/empty/PEmpty'
  import { effectiveType, hasEffective, getEffectiveTypeStatus } from '@/enum/effectiveType'

  export default {
    name: 'CaseCards',
    components: { PImage, PEmpty },
    props: {
      // 案例卡片数组
      caseCardsList: {
        type: Array,
        default: () => [],
        required: true
      },
      // 查询关键字
      searchValue: {
        type: String,
        default: ''
      },
      span: {
        type: Number,
        default: 6
      }
    },
    data() {
      return {
        // 案例加载动画
        caseLoadingAnimation: false,
        hasEffective: hasEffective,
        effectiveType: effectiveType
      }
    },
    created() {
      this.caseLoadingAnimation = false
    },
    watch: {
      // 进行监听，数据值改变（存在）时，动画结束
      caseLoadingAnimationList() {
        this.caseLoadingAnimation = false
      }
    },
    mounted() {

    },
    methods: {
      highlightKeywords,
      routerTo(params) {
        // 有isEffective字段且是无效状态才执行不允许点击跳转
        if (params.isEffective && params.isEffective !== this.hasEffective.VALID) {
          this.alertCasesMsg(params)
          return
        }
        this.$emit('toCaseDetails', params)
      },
      alertCasesMsg(item) {
        this.$message({
          message: `抱歉,该课程${getEffectiveTypeStatus(item.invalidityCause)},请选择其他课程观看`,
          type: 'warning'
        })
      }
    }
  }
</script>

<style lang="less" scoped>
.courseList {
  min-height: 300px;

  .course {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.3);

    .img {
      border-radius: 5px 5px 0 0;
      z-index: -1;
    }

    .description {
      margin-top: 8px;
      text-align: left;
      padding-left: 12px;
      padding-right: 12px;


      .title {
        margin-bottom: 16px;
        line-height: 24px;
        width: 100%;
        height: 48px;
        font-size: 16px;
        overflow: hidden;
        display: -webkit-box; /*弹性伸缩盒子*/
        -webkit-box-orient: vertical; /*子元素垂直排列*/
        -webkit-line-clamp: 2; /*可以显示的行数，超出部分用...表示*/
        text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/

      }

      .number {
        margin-top: 8px;
        margin-bottom: 20px;
        color: #9199a1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: 12px;
          display: flex;
          align-items: center;
        }
      }
    }

  }

  .course:hover {
    cursor: pointer;
    z-index: 2;
    -webkit-box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
    box-shadow: 0px 4px 6px 0px rgba(225, 227, 227, 100);
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }
}
</style>
