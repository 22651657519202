<!--专题案例-->
<template>
  <div class="thematic-background">
    <div class="thematic-cases">
      <div class="header">
        <div class="case-title">
          <img src="~@/assets/index/newCases.png" alt="" width="60" height="32">
          <span>专题案例</span>
        </div>
        <div>
          <span class="all-case" @click="toSpecialCase">更多<i class="el-icon-arrow-right"></i></span>
        </div>
      </div>
      <index-special-cases :specialCasesList="thematicList" @toCaseDetails="toThematicCases"/>
    </div>
  </div>
</template>

<script>
  import PCaseCardsBase from '@comp/project/casecards/PCaseCardsBase'
  import indexSpecialCases from './indexSpecialCases'

  export default {
    name: 'ThematicCases',
    components: { PCaseCardsBase, indexSpecialCases },
    props: {
      thematicList: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {}
    },
    created() {
      this.init()
    },
    methods: {
      init() {

      },
      toThematicCases(item) {
        console.log('item', item)
        let id = item.id || -1
        let routerGo = this.$router.resolve({
          path: '/audit/specialDetail',
          query: {
            id: id
          }
        })
        window.open(routerGo.href, '_blank')
      },
      toSpecialCase() {
        this.$router.push({
          path: '/audit/special',
          query: {}
        })
      }
    }
  }
</script>

<style lang="less" scoped>
.thematic-background {
  padding: 20px 0;
  width: 100%;
  background-color: #FFFFFF;

  .thematic-cases {
    width: @banner-width;
    margin: 0 auto;

    --green-1: #cff0fb;
    --green-2: #cbf4e4;
    --green-3: #22ab80;
    --gray-1: #667280;
    --white-1: #fff;
    --white-2: #f5f7fa;
    background-image: linear-gradient(150deg, var(--green-1) 20%, var(--green-2) 40%);
    padding: 0 12px 12px;
    border-radius: 16px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      font-size: 28px;
      font-weight: bold;
      text-align: left;
      background: url("~@/assets/index/specialTopRight.png") 100% 0 no-repeat;
      background-size: 433px 126px;
      //background: url("~@/assets/index/specialTopRight.png") 100% 0/433px 126px no-repeat;
    }

    .case-title {
      display: flex;
      align-items: center;
      font-size: 20px;
    }

    .all-case {
      font-size: 13px;
      color: #9199a1;
      margin-right: 20px;
      cursor: pointer;
    }
  }

}
</style>
